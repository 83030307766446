import Swiper, { Pagination, Autoplay } from "swiper";

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  function handleDOMContentLoaded() {
    Swiper.use([Pagination, Autoplay]);

    new Swiper('.slideshow', {
      speed: 700,
      autoplay: {
        delay: 7000,
        disableOnInteraction: false
      },
      pagination: {
        el: '.carousel__pagination',
        type: 'bullets',
        hideOnClick: false,
        clickable: true,
        bulletClass: 'carousel__bullet',
        bulletActiveClass: 'carousel__bullet--active'
      },
      loop: true,
      on: {
        slideChangeTransitionEnd: (swiper) => {
          const activeSlide = document.querySelector('.swiper-slide-active');
          document.querySelectorAll('.slide').forEach((el) => el.classList.remove('slide--active'));

          activeSlide.classList.add('slide--active');
        }
      }
    });
  }
})();
